<template>
  <label class="relative ml-2 inline-flex h-full cursor-pointer items-center gap-[10px]">
    <input
      :id="name"
      v-model="vModel"
      :checked.attr="vModel"
      type="checkbox"
      class="peer sr-only"
      :name="name"
      :disabled="disabled"
    />
    <div
      :class="{
        'h-6 w-12 before:h-8 before:w-16 after:size-5': size === 'big',
        'h-4 w-8 before:mt-[-4px] before:h-6 before:w-12 after:size-3': size === 'small',
      }"
      class="peer rounded-full bg-black-30 before:pointer-events-none before:absolute before:ml-[-7.5px] before:mt-[-4px] before:bg-transparent before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:left-[2px] after:top-[2px] after:rounded-full after:border after:border-none after:bg-white after:transition-all after:content-[''] hover:bg-black-20 active:bg-black-40 active:after:bg-black-10 peer-checked:bg-primary-100 peer-checked:after:left-[6px] peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:focus-visible:bg-primary-50 peer-checked:active:after:bg-white peer-checked:peer-hover:bg-primary-120 peer-focus-visible:outline-none peer-focus-visible:before:scale-100 peer-focus-visible:before:border-[1.6px] peer-focus-visible:before:border-solid peer-focus-visible:before:border-success-100 peer-focus-visible:before:opacity-100 peer-focus-visible:before:transition-[box-shadow_0.2s,transform_0.2s] peer-disabled:bg-black-20 peer-disabled:after:bg-black-10 peer-disabled:peer-checked:bg-black-10 peer-disabled:peer-checked:after:bg-black-20"
    ></div>
    <small :for="name">{{ label }}</small>
  </label>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: boolean | string
  size?: 'big' | 'small'
  name: string
  label?: string
  disabled?: boolean
  checkedValue?: string | boolean | number
  uncheckedValue?: string | boolean | number
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  label: '',
  size: 'big',
  checkedValue: true,
  uncheckedValue: false,
})

const vModel = computed({
  get() {
    return props.modelValue === props.checkedValue
  },
  set(value) {
    emits('update:modelValue', value ? props.checkedValue : props.uncheckedValue)
  },
})
</script>

<style scoped></style>
